<template>
    <div class=" ">
        <el-card>
            <div slot="header" class="card_title">
                <span>权限管理/新增角色</span>
            </div>
            <div class="add_main hidden_scroll">
                <span class="font_weight_bold">新增(编辑)角色</span>
                <div class="margin_top_20">
                    <el-form label-position="right" label-width="120px" :model="formLabelAlign">
                        <el-form-item label="角色名称" class="margin_top_20">
                            <el-input v-model="formLabelAlign.type" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="角色描述">
                            <el-input v-model="formLabelAlign.type"   type="textarea"
                            :rows="5" placeholder=""></el-input>
                        </el-form-item>
                       
                        <el-form-item label="功能权限">
                            <el-checkbox-group v-model="checkedAuthoritys" :min="1" :max="2">
                                <el-checkbox v-for="(item,index) in authoritys" :label="item"
                                    :key="index">{{item}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                      
                        <el-form-item>
                            <div class="flex justify_content_c align_items_c">
                                <el-button type="primary" class="custom_btn_width_100">保存</el-button>
                                <el-button class="custom_btn_width_100 ">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {

        name: '',
        data() {
            return {
                value: "",
                formLabelAlign: {
                    name: '',
                    region: '',
                    type: ''
                },
                checkedAuthoritys: [],
                authoritys: ['新增', '编辑', '删除', '刷新', '批量编辑', '批量删除', '批量审核', '批量刷新', '批量强制取消', '详情']

            }
        },

    }
</script>

<style scoped>
    .main {
        width: calc(100% - 40px);
    }

    .add_main {
        width: 70%;
        height: 81vh;
        overflow-y: scroll;
        margin: 0 auto;
    }
</style>